.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
.root-div {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #062656; /* fallback for old browsers */
}

.header-style {
  z-index: 1000;
  background: #062656; /* fallback for old browsers */

  height: 90px;
  width: 100vw;
}

.button-logout {
  color: white !important;
  float: right;
  border-color: white !important;
  margin-top: 8px !important;
}

.MuiTab-root {
  align-items: unset !important;
  -webkit-align-items: unset !important;
  text-align: left;
}

.date-today {
  float: right !important;
  margin-left: auto;
}
.borderer {
  border-bottom: 1px solid #959698;
  border-radius: 5px;
  margin: 5px 0px !important;
  padding: 10px 20px;
}
.MuiAlert-message {
  width: 100% !important;
  display: flex !important;
}

.MuiAlert-icon {
  font-size: 40px !important;
}

.MuiAccordion-root {
  background: #f0f2f5 !important;
  margin-right: 20px !important;
}

.MuiAccordionDetails-root {
  padding: 5px !important;
}

.button-type {
  width: 75px;
  padding: 14px !important;
  margin-top: 0 !important;
}

.date-echeance .MuiFormControl-root {
  width: 160px !important;
  margin: 0 !important;
}

.text-echeance .MuiFormControl-root {
  width: 150px !important;
  margin: 0 !important;
}

.label-maturity {
  margin-top: 15px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.6);
}

.text-echeance .MuiOutlinedInput-root {
  margin-right: 5px;
}

.div-table {
  padding: 0px 10px;
  margin-top: 10px;
  width: 100%;
}

.div-table .MuiPaper-root {
  background: #f0f2f5 !important;
}

.div-table .MuiToolbar-gutters {
  /*background: rgba(0, 0, 0, .03);*/
}

.container {
  max-width: unset !important;
}

.line-app {
  display: flex;
  margin-bottom: 5px;
}

.container {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.container-balance {
  padding: 16px 50px !important;
  background: rgba(0, 0, 0, 0.03);
  margin-top: 20px;
  border: 1px solid grey;
  /*padding-bottom: 16px !important;*/
}

.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  display: none;
}
