
.login-page {
    height: 100vh;
    background-color: #00294d;
  }
  
  .login-box {
    margin: 0 auto;
    width: 400px;
    margin-top: 200px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding : 15px;
    border-radius: 10px;
    
  }
  
  .login-logo {
    background-color: #00294d;
    margin-bottom: 40px;
    width: 100%;  

  }
  
  .login-title {
    text-align: center;
    /*border: 1px solid #fff;*/
    margin-bottom: 10px;
  }
  
 

  .form-container{
      border: 1px solid red;
      padding : 10px;
      margin-bottom: 10%;
  }